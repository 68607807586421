@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nunito-sans-200 {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
}
.nunito-sans-500 {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
}

.nunito-sans-1000 {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
}
p {
  font-family: "Nunito Sans";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.pc {
  color: #d31923;
}
.sc {
  color: #8a4a4e;
}
.pbc {
  background-color: #d31923;
}
.sbc {
  background-color: #8a4a4e;
}

/* Header */

.header-main {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 0px;
}

.header-logo {
  width: 150px;
}
.header-navbar {
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  gap: 20px;
  list-style: none;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 16px;
}
.nav-links a:hover{
  color: #8a4a4e;
}

.nav-links.show {
  display: flex;
  position: absolute;
  text-align: center;
  left: 10px;
  top: 50px;
  right: 20px;
  background-color: white;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.hamburger-menu {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

.header-icons {
  color: rgb(88, 88, 88);
  font-size: 23px;
  cursor: pointer;
}
.header-icons:hover {
  color: #8a4a4e;
}
.header-button {
  background-color: #8a4a4e;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 5px 15px;
  text-decoration: none;
}
.header-overlay {
  position: absolute;
  display: flex;
  column-gap: 10px;
  margin: 10px 10px 0px -70px;
  background-color: whitesmoke;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  z-index: 1;
}
/* Top Level */
.top-level-main {
  margin: 0px;
}
.top-level {
  background-color: #8a4a4e;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  line-height: 2;
  min-height: 400px;
  row-gap: 20px;
  margin: 0px;
}
.top-level-image {
  width: 550px;
  z-index: 1;
  max-width: 100%;
 
}
.toplevel-button {
  border: none;
  background-color: white;
  color: #8a4a4e;
  border-radius: 5px;
  font-size: 12px;
  padding: 5px 10px;
  text-decoration: none;
}
.toplevel-button:hover {
  background-color: whitesmoke;
}
.toplevel-button-alt {
  border: none;
  background-color: #5f2d30;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  padding: 5px 10px;
  text-decoration: none;
  margin-left:10px ;
}
.toplevel-button-alt:hover {
  background-color: #894f53;
}
.toplevel-designs {
  position: absolute;
  z-index: 0;
  font-size: 150px;
  color: #935e61;
}
.toplevel-designs-small {
  position: absolute;
  z-index: 0;
  font-size: 50px;
  color: #935e61;
}
.toplevel-content {
  z-index: 1;
}
.toplevel-triangle {
  fill: #8a4a4e;
  stroke: #8a4a4e;
  stroke-width: 3;
  margin: 0px;
}
.advert {
  background-color: rgb(204, 143, 143);
  color: white;
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 2;
  text-align: center;
}
marquee {
  font-size: 16px;
  font-weight: bold;
}
/* vision and mission */
.vision-mission{
  margin-top:50px ;
}
.vision-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
  padding: 30px;
  flex-wrap: wrap;

}

.vision {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
}

.circle {
  width: 300px;
  height: 300px;
  border: 8px solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.circle-inner {
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vision-number {
  font-size: 18px;
  font-weight: bold;
}

.line {
  width: 2px;
  height: 30px;
  margin: 10px 0;
  background-color: #000;
}

.content {
  text-align: center; 

}

.content h4 {
  font-size: 16px;
  margin: 5px 0;
}

.content p {
  font-size: 16px;
  margin: 5px 0;
}

.content i {
  font-size: 20px;
  margin-top: 5px;
}

/* Why Us */
.identifier {
  padding: 5px;
  background-color: #8a4a4e;
  margin: 0px 5px 0px 50px;
  height: 50px;
}
#identifier {
  padding: 5px;
  background-color: white;
  margin: 0px 5px 0px 50px;
  height: 50px;
}

.whyus-flex {
  display: flex;
  column-gap: 20px;
  row-gap: 0px;
  flex-wrap: wrap;
}
.whyus {
  width: 300px;
  margin: 20px;
}
.rect-one {
  min-width: 50px;
  min-height: 50px;
  background-color: #8a4a4e;
  margin: 5px;
  border-radius: 5px;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vertical-flex {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-left: 20px;
}
/* Featured */
.simple-flex-courses{
  display: flex;
  align-items: stretch;
  gap: 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 20px 10px 20px 50px;
  scrollbar-color: #8a4a4e #f1dcde; 
}
.featured-main {
  padding: 20px 10px 10px 10px;
  background-color: #f1dcde;
  margin-top: 20px;
}
.course-header{
  font-size: 15px;
}
.course-content{
  font-size: 13px;
}
.courses-box{
  background-color: white;
  box-shadow: #8a4a4e 5px 5px 5px;
  min-height: 120px;
  min-width: 200px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.courses-box:hover{
  box-shadow: #8a4a4e 7px 7px 7px;
}
.testimonial-padding {
  padding: 0px 10px; 
  text-align: center;
 
}


/* Contact us */
.contactusmain{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-flow: row wrap;
  padding: 30px;
}
.contactusimg{
  width: 300px;
  margin: 20px;
}
.ready{
  font-size: 17px;
  font-weight: bold;
  margin: 0px;
  text-align: center;
}
.expert{
  font-size: 50px;
  font-weight: bolder;
  color: #8a4a4e;
  margin: 0px;
  text-align: center;
}
.transition{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.submitbut{
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  color: #8a4a4e;
  border: none;
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
}
.contactus-form{
background-color: #8a4a4e;
display: flex;
flex-flow: column;
gap: 20px;
color: white;
padding: 20px;
min-width: 350px;
}
.contactus-form input{
  width: 100%;
  border-radius:5px;
  border: none;
  height: 40px;
}

/* Footer */
.footermain{
  background-color: #6e363a;
  color: white;
  padding: 20px;
  margin: 0px;

}
.footerlogo{
  width: 200px;
  margin-bottom: 10px;
}
.footertop{
  display: flex;
  grid-template-columns: 1.3fr 1fr 1fr;
  align-items: center;
  gap: 30px;
  margin-bottom: 10px;
}
.footerbootcamp{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  /* padding: 10px; */
}
.footerbootcamp input{
  /* width: 100%; */
  /* height: 40px; */
}

.sendbut{
  background-color: #9b5d63;
  padding: 5px 10px;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 16px;
  margin: 1px 0px;
  width: 300px;

}
.footerbootcamp p{
  font-size: 20px;
  font-weight: bolder;
  text-transform: uppercase;
  margin: 5px;
  text-align: center;
}
.footer-phone{
  color: white;
  
}
.compdetail{
  line-height: 2;
}
.hrline{
  margin: 0px 5% 10px 5%;
}
.txt-white{
  color: white;
}
.txt-white:hover{
  color: whitesmoke;
}
.mobile-visible {
  display: none;
}

.mobile-invisible {
  display: block;
}
.simple-flex {
  display: flex;
  align-items: stretch;
  gap: 5px;
  flex-wrap: wrap;
  margin-top:20px ;
}

.flex-between{
  justify-content: center;
  margin: 30px;
  gap: 20px;
}
.page-margin {
  margin: 0px 20px;
}
.bolder {
  font-weight: bolder;
}

.reason-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 50px;
  margin-right: 50px;
}

.reason-box {
  background: #fff;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.reason-box .reason-header{
  font-weight: bold;
  color: #6e363a ;
  
}
.reason-box .reason-content{

}
.toplevel-designs {
  animation: toplevel-designs-spin infinite 20s linear;
}
/* Program */

.program-main {
  padding: 20px 10px 10px 10px;
  background-color: white;
  margin-top: 20px;
}
.prog-img{
  margin: 20px;
  max-height: 300px;
  object-fit: contain;
}

/* faq */
.faq-section{
  background-color: #bc8b8d;
  padding: 20px;
}
.faq-section h2{
  font-size: 24px;
  padding: 10px;
  color: #000;
  margin: 0;
  font-weight: 700;
  line-height: 29px;
}

.faq-section p,
.accordion {
    width: 100%;

}

.accordion .accordion-button:focus,
.accordion .accordion-button:not(.collapsed) {
    box-shadow: none;
}

.accordion .accordion-button:not(.collapsed) {
    background-color: #f1dcde;
    color: #8a4a4e;
}

.accordion .accordion-button:not(.collapsed)::after {
    filter: invert();
}
/* Accordion Styling */
.custom-accordion {
    font-size: 15px;
    padding: 0px 20px; /* Adjust padding as needed */
}

/* testimonials */

.student-review-card{
  padding: 15px 0px;
}
.review-flex{
  display: flex;
  justify-content: space-between;
  margin: 20px; 
  width: 100%; 
  align-items: center; 

}
.left-content {
  display: flex; 
  align-items: center; 
}
.review-button {
  background-color: #8a4a4e;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 10px 25px;
  font-size: 14px;
  cursor: pointer;
}
.red{
  background-color: #f1dcde;
  color: #8a4a4e;
  margin-right: 30px;
}

.review-button:hover {
  background-color: #935e61;
}
/* successstories */
.success-image{
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

/* courses */
.course-list {
  padding: 15px;
  background-color: #8a4a4e;
  color:  #8a4a4e;
}

.course-list h3 {
  text-align: center;
  margin: 20px 0px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: #f1dcde;

}

.course-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

.course-card {
  border: 1px solid #444; 
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3); 
  transition: transform 0.2s;
  background-color: #f1dcde; 
}

.course-card:hover {
  transform: translateY(-5px);
  cursor: pointer;
}

.course-image img {
  width: 100%;
  height: 200px; 
  object-fit: cover; 
}

.course-details {
  padding: 16px;
  text-align: center;
}

.course-details h2 {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.course-details .new-price {
  font-size: 14px;
  color: #8a4a4e;
  margin: 0.5rem 0;
}

.course-details button {
  background-color: #8a4a4e;
  color: #f1dcde;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.course-details button:hover {
  background-color: #3a0f13;

}
/* blog */
.blog-page {
  text-align: center;
  background-color: #f1dcde;
}

.heaader {
  padding: 20px;
}

.heaader h1 {
  font-size: 24px;
  color: #000;
  margin: 0;
  font-weight: 700;
  line-height: 29px;
}

.blog-list {
  display: flex;
  overflow: hidden;
  justify-content: center;
  padding: 20px;
}

.blog-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin: 10px;
  width: 300px;
  transition: transform 0.2s;
}

.blog-card:hover {
  transform: scale(1.05);
}

.blog-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.blog-content {
  padding: 10px;
}

.blog-date {
  font-size: 0.9em;
  color: #777;
}

.blog-title {
  font-size: 16px;
  color: #333;
  margin: 10px 0;
}

.blog-description {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #555;
}
.blog-banner-description {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #555;
}
  .read-more {
    background-color: #8a4a4e;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1em;
  }
  
  .read-more:hover {
    background-color: #572528;
  }

/* Course banner */
.course-banner {
  background-color: #f1dcde; 
  padding: 20px;
  color: #333;
}

.course-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-wrap: wrap;
}

.course-info {
  max-width: 70%;
}

.breadcrumbs span {
  color: #5c5c5c;
  font-size: 1rem;
  margin-right: 5px;
}

.course-description {
  margin: 10px 0;
  font-size: 1.3rem;
  line-height: 1.6;
}

.ratings {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin: 10px 0;
}

.rating-value {
  font-size: 1.5rem;
  color: #004d66;
  margin-right: 5px;
}

.stars {
  color: #004d66;
  margin-right: 5px;
}

.meta-info {
  font-size: 1rem;
  color: #5c5c5c;
}

.course-preview {
  max-width: 30%;
}

.course-preview img {
  width: 100%;
  border-radius: 8px;
}


/* Whatyoulearn */
.what-you-learn {
  max-width: 1200px;
  margin: 20px auto;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}


.what-you-learn h2 {
  font-size: 24px;
  text-align: left;
  margin-bottom: 20px;
}

.learn-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 15px;
}

.learn-item {
  font-size: 16px;
  line-height: 1.6;
  background: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.learn-item::before {
  content: "✔";
  margin-right: 10px;
  color: #4CAF50;
}
.coursereview-container {
  background-color:#8a4a4e;
  color: #fff;
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
.coursereview-container h5, .coursereview-container h6{
  padding: 10px 0px;
}

.coursereview-card {
  border: 1px solid #333;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.avatar {
  background-color: #444;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.name {
  font-size: 1.2rem;
  font-weight: bold;
}

.rating {
  color: #4caf50;
  font-size: 14px;
  margin-bottom: 10px;
}

.text {
  font-size: 1rem;
  line-height: 1.5;
}

.show-more {
  background-color: #f1dcde;
  color: #8a4a4e;
  border: none;
  padding: 5px  10px;
  border-radius: 5px;
  cursor: pointer;
}

.show-more:hover {
  background-color: #6e363a;
}

/* meet your instructor */
.instructor-container {
  padding: 20px;
}


.instructor-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.icon-circle {
  width: 40px;
  height: 40px;
  background-color: #bd8e91;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-circle i {
  font-size: 18px;
  color: #fff;
}

.instructor-label {
  font-size: 1.2rem;
  color: #bd8e91;
  margin: 0;
}

.instructor-heading {
  font-size: 32px;
  font-weight: bold;
  margin: 10px 0 20px;
  text-align: left; 
  color: #8a4a4e;
}

.instructor-content {
  display: flex;
  align-items: center;
  gap: 20px;

}

.instructor-photo{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.instructor-info {
  max-width: 600px;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #8a4a4e;
}

/* Stats Section */
.stats-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-top: 40px;
}

.stat-box {
  background-color: #f1dcde;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: calc(25% - 20px); 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.stat-box h2 {
  font-size: 24px;
  margin: 0 0 10px;
  color: #8a4a4e;
}

.stat-box p {
  font-size: 16px;
  margin: 0;
  color: #8a4a4e;
}

/* About Us */
.about-container{
  margin: 0px 10px;
  padding: 40px;
}
#about-container{
  background-color:#8a4a4e;
  color: white;
  border-radius: 8px;
  margin: 0px 10px;
  padding-top: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
.learn-more {
  background-color: #8a4a4e;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1em;
}
.learn-more a{
  text-decoration: none;
  color:white ;
}

.learn-more:hover {
  background-color: #572528;
}

/*Reviewform  */

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}

.form-group textarea {
  resize: vertical;
  height: 80px;
}
.form-group select{
  width: 100%;
}

.rating-stars {
  display: flex;
  gap: 5px;
}

.rating-stars .star {
  font-size: 24px;
  color: #ccc;
  cursor: pointer;
}

.rating-stars .star.active {
  color: #8a4a4e;
}

.submit-btn:hover {
  background-color:  #673235;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.modal-content h3 {
  margin-bottom: 20px;
}


.submit-btn {
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  background-color:  #8a4a4e;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}


/* Responsive Design */

@media only screen and (max-width: 900px) {
  .course-grid {
    grid-template-columns: repeat(3, 1fr);

  }
}

@media (max-width: 768px) {

  .nav-links {
    display: none;
    flex-direction: column;
    gap: 10px;
  }

  .hamburger-menu {
    display: block;
  }
  .top-level{
    padding-top: 70px;
    flex-wrap: wrap;

  }
  .blog-list {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .blog-card {
    width: 100%;
    margin: 10px 5px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-self: center;

  }
  .instructor-content {
    flex-direction: column;
    text-align: center;
  }

  .stats-container {
    flex-direction: column;
    gap: 10px;
  }

  .stat-box {
    width: 100%;
  }
  .course-header {
    flex-direction: column;
  }

  .course-info {
    max-width: 100%;
  }

  .course-preview {
    max-width: 100%;
    margin-top: 20px;
  }
    .vision-container {
      flex-direction: column;
      align-items: center;
    }
  
    .vision {
      margin-bottom: 20px;
    }
    /* feedback */
    .feedback-form-container {
      padding: 15px;
    }
  
    .submit-btn {
      font-size: 14px;
      padding: 8px;
    }


    .learn-grid {
      grid-template-columns: 1fr; 
      gap: 10px;
    }
  
    .learn-item {
      padding: 6px 8px; 
    }
  
    .bolder {
      font-size: 14px; 
    }
  
    .simple-flex {
      flex-direction: column; 
      align-items: flex-start;
    }
  
    .identifier {
      width: 8px; 
      height: 25px;
    }
    .ratings {
      flex-wrap: wrap;
      font-size: 1rem;
      margin: 10px 0;
    }
  }
  
@media only screen and (max-width: 700px) {
  .reason-grid {
    grid-template-columns: 1fr;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .top-level-image{
    width: 350px;
    text-align: center;

  }
  .whyus {
    width: 100%;
  }
  .footertop{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 15px;
  }
  .mobile-visible {
    display: block;
  }
  .mobile-invisible {
    display: none;
  }

  .identifier {
    padding: 5px;
    background-color: #8a4a4e;
    margin: 0px 5px 0px 0px;
  }
  .faq-section p,
  .accordion {
      width: 100%;
  }
  .vision,
  .mission {
    grid-template-columns: 1fr;
  }
  .course-grid {
    grid-template-columns: repeat(2, 1fr);
  }

}
@media (max-width: 480px) {
  .blog-card {
    width: 100%;
    margin: 10px 5px;
  }
}
@keyframes toplevel-designs-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.image-container img {
  width: 100%;
  height: auto;
  filter: blur(0.5px); /* Apply blur to the entire image */
  mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
  -webkit-mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 0%);
}